var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-tooltip', {
    attrs: {
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on;
        return [_c('div', _vm._g({}, on), [_c('flex-row', {
          attrs: {
            "gap": "4px"
          }
        }, [_vm.device.os.toLowerCase() === 'ios' ? _c('v-icon', {
          attrs: {
            "color": "#B3B3B3"
          }
        }, [_vm._v("mdi-apple")]) : _vm._e(), _vm.device.os.toLowerCase() === 'android' ? _c('i', {
          staticStyle: {
            "color": "#42d47e",
            "display": "flex"
          }
        }, [_c('android-logo', {
          attrs: {
            "height": "24",
            "width": "24"
          }
        })], 1) : _vm._e(), _c('span', [_vm._v(_vm._s(_vm.device.model))])], 1)], 1)];
      }
    }])
  }, [_c('span', [_vm._v(_vm._s(_vm.device.os) + " " + _vm._s(_vm.device.version))])]);

}
var staticRenderFns = []

export { render, staticRenderFns }