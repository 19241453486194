<template>
  <v-tooltip bottom>
    <template #activator="{ on }">
      <div v-on="on">
        <flex-row gap="4px">
          <v-icon v-if="device.os.toLowerCase() === 'ios'" color="#B3B3B3"
            >mdi-apple</v-icon
          >
          <i
            v-if="device.os.toLowerCase() === 'android'"
            style="color: #42d47e; display: flex"
          >
            <android-logo height="24" width="24" />
          </i>
          <span>{{ device.model }}</span>
        </flex-row>
      </div>
    </template>
    <span>{{ device.os }} {{ device.version }}</span>
  </v-tooltip>
</template>

<script>
export default {
  props: {
    device: {
      type: Object,
      required: true,
    },
  },
};
</script>
